<template>
  <div>
    <b-form @submit.prevent="updateUser">
      <b-form-group label="Prénom" label-for="first-name-input">
        <b-form-input
            id="first-name-input"
            v-model="$v.u.firstName.$model"
            type="text"
            required
            placeholder="Renseignez un prénom"
            :state="$v.u.firstName.$dirty ? !$v.u.firstName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.u.firstName.$invalid">
          <span v-if="!$v.u.firstName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.u.firstName.alpha">Vous devez renseigner un prénom valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Nom" label-for="last-name-input">
        <b-form-input
            id="last-name-input"
            v-model="$v.u.lastName.$model"
            type="text"
            required
            placeholder="Renseignez un nom"
            :state="$v.u.lastName.$dirty ? !$v.u.lastName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.u.lastName.$invalid">
          <span v-if="!$v.u.lastName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.u.lastName.alpha">Vous devez renseigner un nom valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Email" label-for="email-input">
        <b-form-input
            id="email-input"
            v-model="$v.u.email.$model"
            type="email"
            required
            placeholder="Renseignez une adresse email"
            :state="$v.u.email.$dirty ? !$v.u.email.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.u.email.$invalid">
          <span v-if="!$v.u.email.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.u.email.email">Vous devez renseigner une addresse email valide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="$v.u.admin.$model" name="check-button" switch>
          Administrateur
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Posts
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-posts-modal
          />
        </template>
        <UserPostsList :posts="uPosts" @remove="removePost"/>
        <b-form-invalid-feedback :state="!$v.uPosts.$invalid">Ajouter au moins un poste</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Leads
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-leads-modal
          />
        </template>
        <UserPostsList :posts="uLeads" @remove="removeLead"/>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
    <AddPostsModal :posts="nonUserPosts" @add="addPost"/>
    <AddLeadsModal :leads="nonUserLeads" @add="addLead"/>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {boolean, name} from "@/validators";

import AddPostsModal from "./modals/AddPostsModal";
import AddLeadsModal from "./modals/AddLeadsModal";
import UserPostsList from "./UserPostsList";

export default {
  props: {
    // copy state to avoid mutation
    user: {
      type: Object,
      required: true,
    },
    userPosts: {
      type: Array,
      required: true,
    },
    userLeads: {
      type: Array,
      required: true,
    },
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      u: Object.assign({}, this.user),
      uPosts: [...this.userPosts],
      uLeads: [...this.userLeads],
    };
  },
  validations: {
    u: {
      firstName: {
        required,
        name,
      },
      lastName: {
        required,
        name,
      },
      email: {
        required,
        email,
      },
      admin: {
        boolean,
      },
    },
    uPosts: {
      required,
    },
  },
  components: {
    AddPostsModal,
    AddLeadsModal,
    UserPostsList,
  },
  computed: {
    nonUserPosts() {
      return this.posts.filter(
          (post) => !this.uPosts.some((uPost) => post.uid === uPost.uid)
      );
    },
    nonUserLeads() {
      return this.posts.filter(
          (post) => !this.uLeads.some((uLead) => post.uid === uLead.uid)
      );
    },
  },
  methods: {
    addPost(post) {
      this.uPosts.push(post);
      this.$v.uPosts.$touch();
    },
    addLead(lead) {
      this.uLeads.push(lead);
    },
    removePost(post) {
      this.uPosts = this.uPosts.filter((uPost) => uPost.uid !== post.uid);
      this.$v.uPosts.$touch();
    },
    removeLead(lead) {
      this.uLeads = this.uLeads.filter((uLead) => uLead.uid !== lead.uid);
    },
    async updateUser() {
      const postsToAdd = this.uPosts.filter(
          (uPost) =>
              !this.userPosts.some((userPost) => uPost.uid === userPost.uid)
      );

      const postsToRemove = this.userPosts.filter(
          (userPost) => !this.uPosts.some((uPost) => userPost.uid === uPost.uid)
      );

      const leadsToAdd = this.uLeads.filter(
          (uLead) =>
              !this.userLeads.some((userLead) => uLead.uid === userLead.uid)
      );

      const leadsToRemove = this.userLeads.filter(
          (userLead) => !this.uLeads.some((uLead) => userLead.uid === uLead.uid)
      );

      try {
        await Promise.all([
          this.$store.dispatch("users/updateUser", this.u),
          this.$store.dispatch("users/posts/addPosts", {
            user: this.u,
            posts: postsToAdd,
          }),
          this.$store.dispatch("users/posts/removePosts", {
            user: this.u,
            posts: postsToRemove,
          }),
          this.$store.dispatch("users/leads/addLeads", {
            user: this.u,
            leads: leadsToAdd,
          }),
          this.$store.dispatch("users/leads/removeLeads", {
            user: this.u,
            leads: leadsToRemove,
          }),
        ])
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la mise à jour de l'utilisateur",
          text: e.message,
        });
        return
      }

      await this.$swal.fire({
        icon: "success",
        title: "Utilisateur modifié",
      });
    },
  },
};
</script>
