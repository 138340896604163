<template>
  <b-modal
      id="add-leads-modal"
      title="Ajouter des leads"
      size="xl"
      @hidden="clearInput"
  >
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              autofocus
              v-model="filterSlug"
              placeholder="Rechercher"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-table
            hover
            striped
            borderless
            head-variant="light"
            :items="filteredLeads"
            :fields="fields"
            v-if="filteredLeads.length > 0"
        >
          <template #cell(name)="lead">
            {{ lead.item.name }}
          </template>
          <template #cell(edit)="lead">
            <div class="row justify-content-end mr-0">
              <AddButton
                  class="no-border"
                  @add="$emit('add', lead.item)"
              />
            </div>
          </template>
        </b-table>
        <span v-else>Aucun lead à ajouter</span>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Fermer</b-button>
    </template>
  </b-modal>
</template>

<script>
import AddButton from "@/components/buttons/AddButton"

export default {
  props: {
    leads: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterSlug: '',
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""}
      ],
    };
  },
  components: {
    AddButton,
  },
  computed: {
    sortedLeads() {
      return this.leads
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
    filteredLeads() {
      if (this.filterSlug) {
        const regex = new RegExp(this.filterSlug, 'i');
        return this.sortedLeads.filter(lead => lead.name.match(regex));
      }
      return this.sortedLeads;
    },
  },
  methods: {
    clearInput() {
      this.filterSlug = '';
    },
  }
};
</script>

