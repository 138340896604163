<template>
  <div>
    <b-list-group v-if="sortedPosts.length > 0">
      <b-list-group-item
          v-for="(post, index) in sortedPosts"
          :key="index"
          class="d-flex justify-content-between align-items-center"
      >
        {{ post.name }}
        <font-awesome-icon
            icon="times"
            :style="{color: 'red'}"
            @click="$emit('remove', sortedPosts[index])"
        />
      </b-list-group-item>
    </b-list-group>
    <span v-else>Aucun élément à afficher</span>
  </div>

</template>

<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedPosts() {
      return this.posts
          .slice()
          .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
    },
  },
};
</script>
