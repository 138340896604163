<template>
  <div>
    <UpdateUserForm
        v-if="ready"
        :user="user"
        :user-posts="userPosts"
        :user-leads="userLeads"
        :posts="posts"
    />
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import UpdateUserForm from "@/components/users/UpdateUserForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdateUserForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      userPosts: (state) => state.users.posts.all,
      userLeads: (state) => state.users.leads.all,
      posts: (state) => state.posts.all,
    }),
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch("users/fetchUser", this.uid),
        this.$store.dispatch("posts/fetchPosts"),
      ]);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération de l'utilisateur",
        text: e.message,
      });
      await this.$router.push({name: 'users'})
      return;
    }
    this.ready = true;
  },
};
</script>