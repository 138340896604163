<template>
  <b-modal
      id="add-posts-modal"
      title="Ajouter des postes"
      size="xl"
      @hidden="clearInput"
  >
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              autofocus
              v-model="filterSlug"
              placeholder="Rechercher"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-table
            hover
            striped
            borderless
            head-variant="light"
            :items="filteredPosts"
            :fields="fields"
            v-if="filteredPosts.length > 0"
        >
          <template #cell(name)="post">
            {{ post.item.name }}
          </template>
          <template #cell(edit)="post">
            <div class="row justify-content-end mr-0">
              <AddButton
                  class="no-border"
                  @add="$emit('add', post.item)"
              />
            </div>
          </template>
        </b-table>
        <span v-else>Aucun poste à ajouter</span>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()">Fermer</b-button>
        </template>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import AddButton from "@/components/buttons/AddButton"

export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterSlug: '',
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""}
      ],
    };
  },
  components: {
    AddButton
  },
  computed: {
    sortedPosts() {
      return this.posts
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
    filteredPosts() {
      if (this.filterSlug) {
        const regex = new RegExp(this.filterSlug, 'i');
        return this.sortedPosts.filter(post => post.name.match(regex));
      }
      return this.sortedPosts;
    },
  },
  methods: {
    clearInput() {
      this.filterSlug = '';
    },
  }
};
</script>

